import React, { useState } from "react";
import { socialMediaMap } from "../../utils/Constants";

const SocialContent = ({
    upload,
    userData,
    watch,
    truncateName,
    selectedPlatform,
    youtubeTitle,
    previewViewProps = {}, // Default to an empty object if not provided
    type = ""
}) => {
    const isImage = upload?.file_type && ["jpg", "jpeg", "png", "gif", "bmp"].includes(
        upload.file_type.toLowerCase()
    );
    const isVideo =
        upload?.file_type &&
        ["mp4", "webm", "ogg"].includes(upload.file_type.toLowerCase());
    const contentType = upload?.content_type;
    const socialMedia = selectedPlatform;

    const formatDate = (date) => {
        const options = {
            day: "numeric",
            month: "long",
            hour: "numeric",
            minute: "numeric",
        };
        return new Intl.DateTimeFormat("en-US", options).format(date);
    };

    const currentDate = new Date();
    const formattedDate = formatDate(currentDate);

    const searchParams = new URLSearchParams(window.location.search);
    const stateString = searchParams.get('state');
    const state = JSON.parse(decodeURIComponent(stateString));
    const invoiceHtml = state?.invoice_cat
    const [invoiceTotalCost, setInvoiceTotalCost] = useState(0);

    const contentRenderMap = {
        instagram: {
            story: () =>
                <section>
                    <div className="story__container" style={{ height: type === 'preview' ? '98vh' : "290px" }}>
                        <div className="d-flex justify-content-center align-items-center my-auto">
                            <section className="post">
                                <img
                                    src={upload?.preview}
                                    alt="Post"
                                    className="story__image"
                                    style={previewViewProps.imageStyle}
                                />
                            </section>
                            <div className="story__content">
                                <div
                                    className="reel__reply w-100"
                                    style={{ display: "flex", alignItems: "center" }}
                                >
                                    <input
                                        type="text"
                                        className="form-control reply-input"
                                        placeholder="Write a reply..."
                                        disabled
                                        style={{ flex: 1, backgroundColor: "#rgb(233 236 239 / 90%)" }}
                                    />
                                    <button
                                        disabled
                                        className="reply-send-button"
                                        style={{
                                            border: "none",
                                            background: "transparent",
                                            color: "white",
                                        }}
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="icon icon-tabler icon-tabler-send"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            strokeWidth="2"
                                            stroke="currentColor"
                                            fill="none"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        >
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <line x1="10" y1="14" x2="21" y2="3" />
                                            <path d="M21 3l-6.5 18a0.55 .55 0 0 1 -1 0l-3.5 -7l-7 -3.5a0.55 .55 0 0 1 0 -1l18 -6.5" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>,
            post: (isImage) =>
                isImage && <div className="feed">
                    <section className="username">
                        <div className="image">
                            <a href='#/' className="no-cursor">
                                <img src="/assets/images/icon-7797704_1280.png" alt="userimg" />
                            </a>
                        </div>
                        <div className="id">
                            <a href='#/' className="no-cursor">
                                Vitel global
                            </a>
                        </div>
                    </section>
                    <section className="post" style={{ height: type === 'preview' ? '75vh' : "180px" }} >
                        <img src={upload?.preview} alt="post-img" style={{
                            ...previewViewProps.imageStyle,
                            maxHeight: type === 'preview' ? '85vh' : '180px'
                        }} />
                    </section>
                    <section className="btn-group">
                        <a href="#/" className="like no-cursor me-2">
                            <span className="material-symbols-outlined">maps_ugc</span>
                        </a>
                        <a href="#/" className="share no-cursor me-2">
                            <span className="material-symbols-outlined">share</span>
                        </a>
                        <a href="#/" className="bookmark no-cursor me-2">
                            <span className="material-symbols-outlined">bookmark</span>
                        </a>
                    </section>
                    <section className="caption" style={{ height: type === 'preview' ? '140px' : "50px", overflow: "scroll" }}>
                        <p>
                            <b>
                                <a
                                    className="id no-cursor"
                                    href='#/'
                                >
                                    Vitel global
                                </a>
                            </b>{" "}
                            {watch ? (
                                type === "preview" ? 
                                watch // Show full content in preview
                                : `${watch?.substring(0, 25)}${watch?.length > 25 ? "..." : ""}` // Truncate in new window
                            ) : (
                                "Check out the caption here..."
                            )}
                        </p>
                    </section>
                </div>,
            reel: (isVideo) =>
                isVideo ? <section>
                    <div className="reel__container" style={{ height: type === 'preview' ? '55vh' : "240px" }}>
                        <video className="reel__video" autoPlay loop muted playsInline style={previewViewProps.imageStyle}>
                            <source
                                src={upload.preview}
                                type={`video/${upload.file_type}`}
                            />
                            Your browser does not support the video tag.
                        </video>
                        <div className="reel__content" style={{ marginTop: type === 'preview' ? ' 30%' : "" }}>
                            <div className="reel__desc">
                                <div className="reel__user">
                                    <img
                                        src="/assets/images/icon-7797704_1280.png"
                                        className="reel__avatar"
                                        alt="User Avatar"
                                    />
                                    <p className="reel__username mb-0">Vitel global</p>
                                    <button className="btn btn-outline-light" disabled>
                                        Follow
                                    </button>
                                </div>
                            </div>
                            <div className="reel__options">
                                <div>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="icon icon-tabler icon-tabler-heart"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <path d="M19.5 13.572l-7.5 7.428l-7.5 -7.428m0 0a5 5 0 1 1 7.5 -6.566a5 5 0 1 1 7.5 6.572"></path>
                                    </svg>
                                </div>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="icon icon-tabler icon-tabler-message-circle"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    strokeWidth="2"
                                    stroke="currentColor"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <path d="M3 20l1.3 -3.9a9 8 0 1 1 3.4 2.9l-4.7 1"></path>
                                </svg>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="icon icon-tabler icon-tabler-send"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    strokeWidth="2"
                                    stroke="currentColor"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <line x1="10" y1="14" x2="21" y2="3"></line>
                                    <path d="M21 3l-6.5 18a0.55 .55 0 0 1 -1 0l-3.5 -7l-7 -3.5a0.55 .55 0 0 1 0 -1l18 -6.5"></path>
                                </svg>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="icon icon-tabler icon-tabler-dots-vertical"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    strokeWidth="2"
                                    stroke="currentColor"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <circle cx="12" cy="12" r="1"></circle>
                                    <circle cx="12" cy="19" r="1"></circle>
                                    <circle cx="12" cy="5" r="1"></circle>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div className="reel__caption-container" style={{
                        padding: '10px',
                        maxHeight: type === 'preview' ? '392px' : '60px', // Adjusted height
                        overflowY: type === 'preview' ? 'auto' : 'auto',
                        background: '#fff',
                        borderTop: '1px solid #ddd',
                        margin: 0
                    }}>
                        <p className="mb-0">
                            {watch ? (
                                type === "preview" ? 
                                watch // Show full content in preview
                                : `${watch?.substring(0, 25)}${watch?.length > 25 ? "..." : ""}` // Truncate in new window
                            ) : (
                                "Check out the caption here..."
                            )}
                        </p>
                    </div>
                </section> : null,
        },
        facebook: {
            post: (isImage) =>
                isImage && <div id="facebook_text">
                    <div className="header">
                        <div className="left-info">
                            <div className="thumbnail">
                                <img src="/assets/images/icon-7797704_1280.png" alt="user-img" />
                            </div>
                            <div className="name-info">
                                <div className="name">
                                    <a href='#/' className="no-cursor" >
                                        Vitel global
                                    </a>
                                </div>
                                <div className="time">{formattedDate}</div>
                            </div>
                        </div>
                        <div className="right-info"></div>
                    </div>
                    
                    {upload?.preview && (
                        <div className="facebook-post-image" style={{ height: type === 'preview' ? '73vh' : "120px", background: `url(${upload?.preview})` }}>
                            {/* <img
                                src={upload?.preview}
                                alt="post-img"
                                className="img-fluid"
                                // style={previewViewProps.imageStyle}
                                style={{ maxHeight: type === 'preview' ? '390px' : "170px", objectFit: 'contain' }}
                            /> */}
                            
                        </div>
                    )}
                    <div className="feedback-action mt-2 ms-2">
                        <div className="fb-wrapper">
                            <i className="material-symbols-outlined" style={{ marginRight: '5px' }}>
                                thumb_up
                            </i>Like
                        </div>
                        <div className="fb-wrapper">
                            <i className="material-symbols-outlined" style={{ marginRight: '5px' }}>
                                maps_ugc
                            </i>
                            Comment
                        </div>
                        <div className="fb-wrapper">
                            <i className="fb-icon share"></i>Share
                        </div>
                    </div>
                    <div className="content" style={{ 
                        wordWrap: type === "preview" && "break-word", 
                        overflow: "auto",
                        maxHeight: type === "preview" ? "150px" : "60px", // Reduced height for non-preview
                        overflowY: type === "preview" ? "auto" : "auto",
                        padding: "10px",
                        background: '#fff',
                        // border: '1px solid #ddd'
                    }}>
                        {watch ? (
                            type === "preview" ? 
                            watch // Show full content in preview with scroll
                            : `${watch?.substring(0, 25)}${watch?.length > 25 ? "..." : ""}` // Truncate in new window
                        ) : (
                            "Check out the caption here..."
                        )}
                    </div>
                </div>,
            text: () => <div id="facebook_text">
                <div className="header">
                    <div className="left-info">
                        <div className="thumbnail">
                            <img src="/assets/images/icon-7797704_1280.png" alt="userimg" />
                        </div>
                        <div className="name-info">
                            <div className="name">
                                <a href='#/' className="no-cursor" >
                                    Vitel global
                                </a>
                            </div>
                            <div className="time">{formattedDate}</div>
                        </div>
                    </div>
                    <div className="right-info"></div>
                </div>
                <div className="content" style={{ 
                    wordWrap: type === "preview" && "break-word",
                    overflow: "auto",
                    maxHeight: type === "preview" ? "850px" : "250px", // Taller height for default view
                    overflowY: "auto", // Always show scroll when needed
                    padding: "10px",
                    background: '#fff',
                    // border: '1px solid #ddd',
                    margin: "10px 0"
                }}>
                    {watch ? (
                        type === "preview" ? 
                        watch // Show truncated content in preview mode
                        : `${watch?.substring(0, 25)}${watch?.length > 25 ? "..." : ""}` // Show full content in default view
                    ) : (
                        "Check out the caption here..."
                    )}
                </div>
                <div className="feedback-action">
                    <div className="fb-wrapper">
                        <i className="material-symbols-outlined" style={{ marginRight: '5px' }}>
                            thumb_up
                        </i>Like
                    </div>
                    <div className="fb-wrapper">
                        <i className="material-symbols-outlined" style={{ marginRight: '5px' }}>
                            maps_ugc
                        </i>
                        Comment
                    </div>
                    <div className="fb-wrapper">
                        <i className="fb-icon share"></i>Share
                    </div>
                </div>
            </div>,
            video: (isVideo) =>
                isVideo && <section>
                    <div className="reel__container" style={{ height: type === 'preview' ? '55vh' : "240px" }}>
                        <video className="reel__video" autoPlay loop muted playsInline style={previewViewProps.imageStyle}>
                            <source
                                src={upload.preview}
                                type={`video/${upload.file_type}`}
                            />
                            Your browser does not support the video tag.
                        </video>
                        <div className="reel__content" style={{ marginTop: type === 'preview' ? ' 30%' : "" }}>
                            <div className="reel__desc">
                                <div className="reel__user">
                                    <img
                                        src="/assets/images/icon-7797704_1280.png"
                                        className="reel__avatar"
                                        alt="User Avatar"
                                    />
                                    <p className="reel__username mb-0">Vitel global</p>
                                    <button className="btn btn-outline-light" disabled>
                                        Follow
                                    </button>
                                </div>
                            </div>
                            <div className="reel__options">
                                <div>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="25"
                                        height="25"
                                        fill="currentColor"
                                        className="bi bi-hand-thumbs-up"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M8.864.046C7.908-.193 7.02.53 6.956 1.466c-.072 1.051-.23 2.016-.428 2.59-.125.36-.479 1.013-1.04 1.639-.557.623-1.282 1.178-2.131 1.41C2.685 7.288 2 7.87 2 8.72v4.001c0 .845.682 1.464 1.448 1.545 1.07.114 1.564.415 2.068.723l.048.03c.272.165.578.348.97.484.397.136.861.217 1.466.217h3.5c.937 0 1.599-.477 1.934-1.064a1.86 1.86 0 0 0 .254-.912c0-.152-.023-.312-.077-.464.201-.263.38-.578.488-.901.11-.33.172-.762.004-1.149.069-.13.12-.269.159-.403.077-.27.113-.568.113-.857 0-.288-.036-.585-.113-.856a2 2 0 0 0-.138-.362 1.9 1.9 0 0 0 .234-1.734c-.206-.592-.682-1.1-1.2-1.272-.847-.282-1.803-.276-2.516-.211a10 10 0 0 0-.443.05 9.4 9.4 0 0 0-.062-4.509A1.38 1.38 0 0 0 9.125.111zM11.5 14.721H8c-.51 0-.863-.069-1.14-.164-.281-.097-.506-.228-.776-.393l-.04-.024c-.555-.339-1.198-.731-2.49-.868-.333-.036-.554-.29-.554-.55V8.72c0-.254.226-.543.62-.65 1.095-.3 1.977-.996 2.614-1.708.635-.71 1.064-1.475 1.238-1.978.243-.7.407-1.768.482-2.85.025-.362.36-.594.667-.518l.262.066c.16.04.258.143.288.255a8.34 8.34 0 0 1-.145 4.725.5.5 0 0 0 .595.644l.003-.001.014-.003.058-.014a9 9 0 0 1 1.036-.157c.663-.06 1.457-.054 2.11.164.175.058.45.3.57.65.107.308.087.67-.266 1.022l-.353.353.353.354c.043.043.105.141.154.315.048.167.075.37.075.581 0 .212-.027.414-.075.582-.05.174-.111.272-.154.315l-.353.353.353.354c.006.005.041.05.041.17a.9.9 0 0 1-.121.416c-.165.288-.503.56-1.066.56z" />
                                    </svg>
                                </div>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="icon icon-tabler icon-tabler-message-circle"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    strokeWidth="2"
                                    stroke="currentColor"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <path d="M3 20l1.3 -3.9a9 8 0 1 1 3.4 2.9l-4.7 1"></path>
                                </svg>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    strokeWidth="2"
                                    stroke="white"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    <circle cx="5" cy="12" r="2" fill="white"></circle>
                                    <circle cx="19" cy="5" r="2" fill="white"></circle>
                                    <circle cx="19" cy="19" r="2" fill="white"></circle>
                                    <path
                                        d="M7 12l10-7m0 14L7 12"
                                        stroke="white"
                                        stroke-width="2"
                                    ></path>
                                </svg>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="icon icon-tabler icon-tabler-dots-vertical"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    strokeWidth="2"
                                    stroke="currentColor"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <circle cx="12" cy="12" r="1"></circle>
                                    <circle cx="12" cy="19" r="1"></circle>
                                    <circle cx="12" cy="5" r="1"></circle>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div className="facebook-video-caption" style={{
                        padding: '10px',
                        maxHeight: type === 'preview' ? '392px' : '60px', // Adjusted height
                        overflowY: type === 'preview' ? 'auto' : 'auto',
                        background: '#fff',
                        borderTop: '1px solid #ddd',
                        margin: 0
                    }}>
                        <p className="mb-0">
                            {watch ? (
                                type === "preview" ? 
                                watch // Show full content in preview with scroll
                                : `${watch?.substring(0, 25)}${watch?.length > 25 ? "..." : ""}` // Truncate in new window
                            ) : (
                                "Check out the caption here..."
                            )}
                        </p>
                    </div>
                </section>,
        },
        youtube: {
            video: (isVideo) =>
                isVideo &&
                <div className="user-images user-images-icon-play custom-border rounded mb-3">
                    <a href="#/">
                        <video
                            src={upload.preview}
                            className="img-fluid"
                            muted
                            loop
                            controls
                            style={{
                                width: '100%',
                                height: type === 'preview' ? '90vh' : '230px',
                                objectFit: 'contain',
                                backgroundColor: '#000'
                            }}
                        >
                            Your browser does not support the video tag.
                        </video>
                        <div className="center-icon-plays">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={50}
                                height={50}
                                fill="currentColor"
                                className="bi bi-play-circle-fill"
                                viewBox="0 0 16 16"
                            >
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814z" />
                            </svg>
                        </div>
                    </a>
                    <div>
                        <ul className="notification-list m-0 p-0">
                            <li className="d-flex align-items-center justify-content-between">
                                <div className="w-100">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="ms-2">
                                            <h6 className="fw-500">
                                                {youtubeTitle != '' ? youtubeTitle : upload.file_name || "-"}
                                            </h6>
                                            <p className="mb-0">
                                                No View &#9679; 1 second ago
                                            </p>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <div className="card-header-toolbar d-flex align-items-center">
                                                <div className="dropdown">
                                                    <div
                                                        className="dropdown-toggle"
                                                        id="dropdownMenuButton"
                                                    >
                                                        {/* <span className="material-symbols-outlined">
                                                            more_vert
                                                        </span> */}
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>,
        },
    };

    const renderContent = () => {
        const platform = socialMediaMap[socialMedia];

        if (!platform) {
            console.warn("Platform not found for:", socialMedia);
            return <p>Unsupported platform</p>;
        }

        const platformContent = contentRenderMap[platform];
        if (!platformContent) {
            console.warn("Content not found for platform:", platform);
            return <p>Content not found</p>;
        }

        const renderFn = platformContent[contentType];
        if (!renderFn) {
            console.warn(
                `Render function not found for content type: ${contentType} on platform: ${platform}`
            );
            return <p>Unsupported content type</p>;
        }

        // Call the render function dynamically
        return typeof renderFn === "function"
            ? renderFn(contentType === "post" ? isImage : isVideo)
            : renderFn;
    };

    return (
        <div style={previewViewProps.containerStyle}>
            {renderContent()}
        </div>
    );
};

export default SocialContent;
